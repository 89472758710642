.main-head {
  margin-bottom: 25px;
  color: var(--white);
}

.app__cert-portfolio {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.certifications__main {
  width: 180px;
  flex-direction: column;
  flex-wrap: wrap;
  height: 100%;
  margin: 2rem;
  padding: 0.5rem;
  border-radius: 0.5rem;
  background: linear-gradient(to right, var(--light-blue), var(--dark-blue));
  color: var(--white);
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative; 

  &:hover {
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.2);
  }

  @media screen and (min-width: 2000px) {
    width: 470px;
    padding: 1.25rem;
    border-radius: 0.75rem;
  }

  @media screen and (max-width: 300px) {
    width: 100%;
    margin: 1rem;
  }
}

.app__cert-hover {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: all 0.3s ease;

  div {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
    margin: 1rem;
    font-family: var(--font-base);
    font-weight: 800;
    cursor: pointer;
    transition: all 0.3s ease;

    svg {
      width: 50%;
      height: 50%;
      color: var(--white);
    }
  }
}

.app__cert-img {
  width: 100%;
  height: 150px;
  background-color: white;
  position: relative;
  border-radius: 1rem;

  img {
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    object-fit: cover;
  }

  @media screen and (min-width: 2000px) {
    height: 350px;
  }
}

.app__cert-content {
  padding: 0.5rem;
  width: 100%;
  
  position: relative;
  flex-direction: column;
  overflow: hidden; /* Add this line to hide overflowing content */
  text-overflow: ellipsis; /* Add this line to add ellipsis to long text */
  white-space: wrap; /* Add this line to prevent wrapping */

  h4 {
    font-size: 1rem;
    line-height: 1.2;
    width: 100%;
    margin: 0;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: wrap;
  }

  .p-text {
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

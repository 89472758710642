// .app__footer {
//     flex: 1;
//     width: 100%;
//     flex-direction: column;

//     @media screen and (max-width: 768px) {
//       flex-direction: column;
//     }
//   }
.round__container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}
// .round__right{
//   display: flex;
//   align-items: flex-start;
//   justify-content: flex-start;
//   padding: 100px;
//   border-radius: 50%;
//   position: absolute;
//   margin-left:-60px;
//   margin-top: -70px;
//   background: linear-gradient(to top, #473cc2b1, #4f9ed7a9);
// }
// .round__left{
//   display: flex;
//   align-items: flex-end;
//   justify-content: flex-end;
//   padding: 100px;
//   border-radius: 50%;
//   position: absolute;
//   margin-left:-60px;
//   margin-top: -70px;
//   background: linear-gradient(to top, #473cc2b1, #4f9ed7a9);
// }
.button-resume{
  display: flex;
  align-items: center;
  justify-content: center;
}
.main__footer {
  padding: 10px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border-top-right-radius: 50px;
  border-bottom-left-radius: 50px;

  background: linear-gradient(to right, #473cc2b1, #4f9ed7a9);
}
.footer__contianer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
.head__drop {
  font-size: 35px;
}
.icon__style {
  margin: 5px;
  padding: 10px;
  border-radius: 10px;
  background: linear-gradient(to left, var(--dark-blue), var(--light-blue));
  &:hover {
    background: linear-gradient(to right, var(--dark-blue), var(--light-blue));
  }
}
.contact_card {
  display: flex;
  justify-content: center;
  align-items: center;
  a {
    text-decoration: none;
    color: var(--white);
  }
}
.contact_container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: 10%;
  margin-bottom: 20px;
  @media screen and (max-width: 768px) {
    margin-left: 20%;
  }
  h2 {
    color: var(--white);
    font-size: 20px;
    padding-bottom: 5px;
    padding-left: 10px;
  }
  p {
    width: 80%;
    padding-left: 10px;
    color: white;
  }
}
.icons {
  font-size: 1.5rem;
}
.message__container {
  height: 300px;
  width: 80%;
  
  border-radius: 20px;
  background: linear-gradient(
    to bottom right,
    var(--light-blue),
    var(--dark-blue)
  );
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.2);
  padding: 20px;
  margin: 10px;
  div {
    input {
      padding: 10px;
      color: white;
      outline: none;
      margin-bottom: 8px;
      border-radius: 8px;
      width: 100%;
      border: none;
      background-color: var(--background);
    }
    textarea{
      padding: 10px;
      color: white;
      outline: none;
      margin-bottom: 8px;
      border-radius: 8px;
      width: 100%;
      height: 100px;
      border: none;
      background-color: var(--background);
    }
  }
  @media screen and (max-width: 768px) {
    margin-top: 25px;
    margin-bottom: 25px;
    width: 75%;
  }
  @media screen and (min-width: 768px) {
    margin-top: 25px;
    margin-bottom: 25px;
    width: 60%;
    margin-right: 8%;
  }
  @media screen and (min-width: 800px) and (max-width:1200px){
    margin-top: 25px;
    margin-bottom: 25px;
    width: 100%;
    margin-right: 8%;
  }
  button {
    padding: 15px;
    background-color: var(--background);
    color: white;
    border-radius: 5px;
    &:hover{
      cursor: pointer;
    }
  }
}
.message_note{
  margin: 20px;
  font-size: large;
  color: var(--white);
}
// .app__footer-cards {
//   width: 60%;
//   display: flex;
//   justify-content: space-evenly;
//   align-items: center;
//   flex-wrap: wrap;
//   margin: 4em 2rem 2rem;

//   .app__footer-card {
//     min-width: 290px;
//     display: flex;
//     flex-direction: row;
//     justify-content: flex-start;
//     align-items: center;

//     margin: 1rem 0;
//     padding: 1rem;
//     border-radius: 10px;
//     cursor: pointer;
//     background-color: #fef4f5;

//     transition: all 0.3s ease-in-out;

//     img {
//       width: 40px;
//       height: 40px;
//       margin: 0 0.7rem;
//     }

//     p {
//       font-weight: 500;
//     }
//     a {
//       text-decoration: none;
//       font-weight: 500;
//     }
//     // &:hover {
//     //   box-shadow: 0 0 25px gray;
//     // }

//     @media screen and (max-width: 450px) {
//       width: 100%;
//     }
//   }

//   @media screen and (max-width: 768px) {
//     width: 100%;
//   }
// }

// .app__footer-cards .app__footer-card:last-child {
//   background-color: #f2f7fb;
// }

// .app__footer-cards .app__footer-card:last-child:hover {
//   box-shadow: 0 0 25px #f2f7fb;
// }

// .app__footer-form {
//   width: 60%;
//   flex-direction: column;
//   margin: 1rem 2rem;

//   div {
//     width: 100%;

//     margin: 0.75rem 0;
//     border-radius: 10px;
//     cursor: pointer;
//     background-color: #f2f7fb;

//     transition: all 0.3s ease-in-out;

//     input,
//     textarea {
//       width: 100%;
//       padding: 0.95rem;
//       border: none;
//       border-radius: 7px;
//       background-color: #f2f7fb;

//       font-family: var(--font-base);
//       color: black;
//       outline: none;
//     }

//     textarea {
//       height: 170px;
//     }

//     &:hover {
//       box-shadow: 0 0 25px gray;
//     }
//   }

//   button {
//     padding: 1rem 2rem;
//     border-radius: 10px;
//     border: none;
//     background-color: black;

//     font-weight: 500;
//     color: var(--white);
//     outline: none;
//     margin: 2rem 0 0 0;
//     font-family: var(--font-base);

//     transition: cubic-bezier(0.55, 0.085, 0.68, 0.53);
//     cursor: pointer;

//     &:hover {
//       background-color: gray;
//     }
//   }

//   @media screen and (max-width: 768px) {
//     width: 100%;
//     margin: 1rem 0;
//   }
// }
.app__about {
  flex: 1;
  width: 100%;
  flex-direction: column;
}

.app__profiles {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-top: 2rem;
}
.app__profile-item {
  width: 200px;
  height: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #ffffff17;
  border-radius: 30px;
  padding: 20px;
  margin: 2rem;
  .title-color{
    color: var(--white);
  }
 .p-color{
  color: var(--white);
 }
  img {
    width: 100%;
    height: 170px;
    border-radius: 15px;
    object-fit: cover;
  }
  @media screen and (min-width: 2000px) {
    width: 370px;
    margin: 2rem 4rem;

    img {
      height: 320px;
    }
  }
}
.about__head{
  padding-bottom:40px ;
  color: var(--white);
  span{
    color: var(--light-blue);
  }
  small{
    color: var(--dark-blue); 
    font-size: 44px;
  }
  @media screen and (max-width:450px) {
    small{
      color: var(--dark-blue); 
      font-size: 32px;
    }
  }
  @media screen and (min-width: 2000px) {
    small{
      color: var(--dark-blue); 
      font-size: 64px;
    }
  }
}
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");

:root {
  --font-base: "DM Sans", sans-serif;

  --black: black;
  --white: #ffffff;
  --background: #101733;
  --light-blue: #4f9ed7;
  --dark-blue:#483CC2;

  /* --light-pink-color: #e8b7d4;
  --dark-pink-color: #a5678e;
  --light-blue-color: #7facd6;
  --dark-blue-color: #33539e;
  --light-purple-color: #bfb8da;
  --cream-color: #fcfaf2;
  --dark-cream-color: #ffbfc5; */
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}
#home {
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: repeat;
  height: 100%;
  width: 100%;
  background-color: var(--background);
  // background-image: url("../../assets/bgcode.jpg");
  // disable the default padding styles
  // background-color: rgba(255, 255, 255, 0.088);

  position: relative;
  .app__wrapper {
    padding: 0;

    .copyright {
      display: none;
    }
  }
}

.app__header {
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 6rem 2rem 0rem;

  @media screen and (min-width: 2000px) {
    padding-top: 8rem;
  }

  @media screen and (max-width: 1200px) {
    flex-direction: column;
  }

  @media screen and (max-width: 450px) {
    padding: 6rem 1rem 2rem;
  }
}

.app__header-info {
  flex: 0.85;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin: 0 2rem;
  width: 100%;

  @media screen and (max-width: 1200px) {
    width: 100%;
    margin-right: 0rem;
  }
}

.app__header-badge {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .badge-cmp,
  .tag-cmp {
    padding: 2rem 5rem;
    background: linear-gradient(to left, var(--light-blue), var(--dark-blue));
    border-radius: 15px;
    flex-direction: row;
    width: auto;

    box-shadow: 0px 0px 20px var(--dark-blue);
  }

  .tag-cmp {
    flex-direction: column;
    margin-top: 3rem;

    p {
      width: 100%;
      text-transform: uppercase;
      text-align: right;
      font-weight: 700;
      font-style: italic;
    }
  }

  span {
    font-size: 2.5rem;

    @media screen and (min-width: 2000px) {
      font-size: 5rem;
    }
  }
  @media screen and (max-width:500px){
    .badge-cmp,
    .tag-cmp {
      padding: 1rem 2rem;
    }
  }

  @media screen and (max-width: 1200px) {
    justify-content: flex-start;
    align-items: flex-start;
    
  }
}

.app__header-circles {
  flex: 0.45;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  height: 100%;

  // margin-left:40%;

  div {
    width: 100px;
    height: 100px;
    border-radius: 50%;

    background: var(--light-blue);
    box-shadow: 0px 0px 20px var(--dark-blue);

    img {
      width: 60%;
      height: 60%;
    }
  }

  // circles with different height & width

  div:nth-child(1) {
    width: 100px;
    height: 100px;
  }

  div:nth-child(2) {
    margin: 1.75rem;
    width: 150px;
    height: 150px;
  }

  div:nth-child(3) {
    width: 70px;
    height: 70px;
  }

  @media screen and (min-width: 2000px) {
    div:nth-child(2) {
      width: 400px;
      height: 400px;
    }

    div:nth-child(3) {
      width: 170px;
      height: 170px;
    }

    div:nth-child(1) {
      width: 200px;
      height: 200px;
    }
  }

  @media screen and (max-width: 1200px) {
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: 0;

    div {
      margin: 1rem;
    }
  }
}

.app__header-img {
  flex: 1;
  height: 100%;

  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  position: relative;

  img {
    width: 100%;
    object-fit: contain;
    z-index: 1;
  }

  .overlay_circle {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
    width: 100%;
    height: 90%;
  }

  @media screen and (max-width: 1200px) {
    margin: 2rem 0;
  }
}
.color-white {
  color: var(--white) !important;
}
.resume-button{
  margin:40px ;
  background: linear-gradient(to right, var(--light-blue), var(--dark-blue));
  padding: 10px;
  width: 220px;
  height: 50px;
  border: none;
  font-size: 15px;
  border-radius: 10px;
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-self: center;
  text-decoration: none;
  a{
    color: --white;
  }
  &:hover {
    background: linear-gradient(to left, var(--light-blue), var(--dark-blue));
  }
}
.text_1 {
  animation: text1;
}

.text_2 {
  animation: text2;
}

.text_1, .text_2 {
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;
  position: relative;
  animation-duration: 20s;
  animation-timing-function: steps(25, end);
  animation-iteration-count: infinite;
}

.text_1::after, .text_2::after {
  content: "|";
  position: absolute;
  right: 0;
  animation: caret infinite;
  animation-duration: 1s;
  animation-timing-function: steps(1, end);
}

@keyframes text2 {
  0%, 50%, 100% {
    width: 0;
  }

  60%, 90% {
    width: 10.4em;
  }
}

@keyframes text1 {
  0%, 50%, 100% {
    width: 0;
  }
  10%, 40% {
    width: 11.8em;
  }
}

@keyframes caret {
  0%, 100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}